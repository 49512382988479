import {
  CreateEntityLocationPayload,
  CreateEntityProviderPayload,
} from '#/types';

export const affiliationOptions = ['Direct', 'Delegated'];

export const adaComplianceQuestions = [
  {
    title: 'Handicap accessibility',
    subTitle:
      'Ramped entrance and exit spaces, the presence of wider doors than a standard unit, for wheelchair accessibility and with enough space inside to perform a three-point turn in a wheelchair.',
    name: 'handicapAccessibility',
  },
  {
    title: 'Accessibility to the building: Entrances',
    subTitle:
      'Entrances with ramps or elevators, clear pathways, and appropriate signage to guide individuals with disabilities.',
    name: 'accessibilityToTheBuildingEntrances',
  },
  {
    title: 'Accessibility to the building: Spaces',
    subTitle:
      'The spaces should be wide enough and have an accessible route to the entrance.',
    name: 'accessibilityToTheBuildingSpaces',
  },
  {
    title: 'Reception and waiting areas',
    subTitle:
      'Section at a lower height to accommodate individuals who use wheelchairs. Waiting areas should have accessible seating options and sufficient manoeuvring space for wheelchair users.',
    name: 'receptionAndWaitingAreas',
  },
  {
    title: 'Exam rooms and treatment areas',
    subTitle:
      'At least one exam room should be designed to accommodate individuals with disabilities, including sufficient space for wheelchair manoeuvrability, adjustable examination tables, and accessible medical equipment.',
    name: 'examRoomsAndTreatmentAreas',
  },
  {
    title: 'Restrooms',
    subTitle:
      'Dimensions, grab bars, sinks, and other features to ensure usability for individuals with disabilities.Dimensions, grab bars, sinks, and other features to ensure usability for individuals with disabilities.',
    name: 'restrooms',
  },
  {
    title: 'Signage and wayfinding',
    subTitle:
      'Clear and visible signage to guide individuals with disabilities and help them locate different areas, such as examination rooms, restrooms, and exits.',
    name: 'signageAndWayfinding',
  },
  {
    title: 'Communication accommodations',
    subTitle:
      'Effective communication for individuals with hearing, vision, or speech disabilities. This may include the availability of sign language interpreters, captioning services, or assistive listening devices.',
    name: 'communicationAccomodations',
  },
  {
    title: 'Policies and procedures',
    subTitle:
      'Equal access and non-discrimination for individuals with disabilities',
    name: 'policiesAndProcedures',
  },
];

export const adaComplianceFilterOptions = ['Yes', 'No', 'Not known'];
export const entityLocationTypes = [
  { label: 'Primary Care', value: 'primaryCare' },
  { label: 'Specialty Care', value: 'specialtyCare' },
  { label: 'Behavioral Health', value: 'behavioralHealth' },
  { label: 'Urgent Care', value: 'urgentCare' },
  { label: 'Surgical Services', value: 'surgicalServices' },
  { label: 'Rehabilitation Services', value: 'rehabilitationServices' },
  { label: 'Laboratory Services', value: 'laboratoryServices' },
  { label: 'Imaging Services', value: 'imagingServices' },
  { label: 'Long-Term Care', value: 'longTermCare' },
  { label: 'Hospice or Palliative Care', value: 'hospiceOrPalliativeCare' },
  { label: 'Home Health Services', value: 'homeHealthServices' },
  { label: 'Others', value: 'others' },
];

export const entityLocationServiceCategory = [
  { label: 'Ambulance - Air or Water (042)', value: 'ambulanceAirOrWater042' },
  { label: 'Ambulance - Land (041)', value: 'ambulanceLand041' },
  {
    label: 'Ambulatory Surgical Center (024)',
    value: 'ambulatorySurgicalCenter024',
  },
  {
    label: 'Assisted Living Facility (013)',
    value: 'assistedLivingFacility013',
  },
  { label: 'Birthing Center (025)', value: 'birthingCenter025' },
  {
    label: 'Community Mental Health Center (053)',
    value: 'communityMentalHealthCenter053',
  },
  {
    label: 'Comprehensive Inpatient Rehabilitation Facility (061)',
    value: 'comprehensiveInpatientRehabilitationFacility061',
  },
  {
    label: 'Comprehensive Outpatient Rehabilitation Facility (062)',
    value: 'comprehensiveOutpatientRehabilitationFacility062',
  },
  { label: 'Custodial Care Facility (033)', value: 'custodialCareFacility033' },
  { label: 'Emergency Room-Hospital (023)', value: 'emergencyRoomHospital023' },
  {
    label: 'End-Stage Renal Disease Treatment Facility (065)',
    value: 'endStageRenalDiseaseTreatmentFacility065',
  },
  {
    label: 'Federally Qualified Health Center (050)',
    value: 'federallyQualifiedHealthCenter050',
  },
  { label: 'Group Home (014)', value: 'groupHome014' },
  { label: 'Hospice (034)', value: 'hospice034' },
  { label: 'Independent Clinic (049)', value: 'independentClinic049' },
  { label: 'Independent Laboratory (081)', value: 'independentLaboratory081' },
  {
    label: 'Indian Health Service Free-standing Facility (005)',
    value: 'indianHealthServiceFreeStandingFacility005',
  },
  {
    label: 'Indian Health Service Provider-based Facility (006)',
    value: 'indianHealthServiceProviderBasedFacility006',
  },
  { label: 'Inpatient Hospital (021)', value: 'inpatientHospital021' },
  {
    label: 'Inpatient Psychiatric Facility (051)',
    value: 'inpatientPsychiatricFacility051',
  },
  {
    label:
      'Intermediate Care Facility/Individuals with Intellectual Disabilities (054)',
    value:
      'intermediateCareFacilityForIndividualsWithIntellectualDisabilities054',
  },
  {
    label: 'Mass Immunization Center (060)',
    value: 'massImmunizationCenter060',
  },
  {
    label: 'Military Treatment Facility (026)',
    value: 'militaryTreatmentFacility026',
  },
  { label: 'Mobile Unit (015)', value: 'mobileUnit015' },
  {
    label: 'Non-residential Substance Abuse Treatment Facility (057)',
    value: 'nonResidentialSubstanceAbuseTreatmentFacility057',
  },
  { label: 'Nursing Facility (032)', value: 'nursingFacility032' },
  {
    label: 'Off Campus-Outpatient Hospital (019)',
    value: 'offCampusOutpatientHospital019',
  },
  { label: 'Office (011)', value: 'office011' },
  { label: 'Outpatient Hospital (022)', value: 'outpatientHospital022' },
  { label: 'Pharmacy (001)', value: 'pharmacy001' },
  {
    label: 'Place of Employment/Worksite (018)',
    value: 'placeOfEmploymentOrWorksite018',
  },
  {
    label: 'Prison/Correctional Facility (009)',
    value: 'prisonCorrectionalFacility009',
  },
  {
    label: 'Psychiatric Facility-Partial Hospitalization (052)',
    value: 'psychiatricFacilityPartialHospitalization052',
  },
  {
    label: 'Psychiatric Residential Treatment Center (056)',
    value: 'psychiatricResidentialTreatmentCenter056',
  },
  { label: 'Public Health Clinic (071)', value: 'publicHealthClinic071' },
  {
    label: 'Residential Substance Abuse Treatment Facility (055)',
    value: 'residentialSubstanceAbuseTreatmentFacility055',
  },
  { label: 'Rural Health Clinic (072)', value: 'ruralHealthClinic072' },
  { label: 'School (003)', value: 'school003' },
  {
    label: 'Skilled Nursing Facility (031)',
    value: 'skilledNursingFacility031',
  },
  {
    label: 'Telehealth Provided in Patient’s Home (010)',
    value: 'telehealthProvidedInPatientsHome010',
  },
  {
    label: 'Telehealth Provided Other than in Patient’s Home (002)',
    value: 'telehealthProvidedOtherThanInPatientsHome002',
  },
  { label: 'Temporary Lodging (016)', value: 'temporaryLodging016' },
  {
    label: 'Tribal 638 Free-standing Facility (007)',
    value: 'tribal638FreeStandingFacility007',
  },
  {
    label: 'Tribal 638 Provider-based Facility (008)',
    value: 'tribal638ProviderBasedFacility008',
  },
  { label: 'Urgent Care Facility (020)', value: 'urgentCareFacility020' },
  {
    label: 'Walk-in Retail Health Clinic (017)',
    value: 'walkInRetailHealthClinic017',
  },
  { label: 'Other Place of Service (099)', value: 'otherPlaceOfService099' },
];

export const entityLocationContactType = [
  { label: 'Home/Residence', value: 'home/residence' },
  { label: 'Office Practice', value: 'officePractice' },
  { label: 'Emergency/On-Call', value: 'emergency/on-call' },
  { label: 'Others', value: 'others' },
];

export const entitySupportingDocuments = [
  { name: 'certificateOfInsurance', label: 'Certificate of Insurance' },
  { name: 'disclosureOfOwnershipForm', label: 'Disclosure Of Ownership Form' },
  { name: 'eftForm', label: 'EFT Form' },
  { name: 'letterOfAppeal', label: 'Letter Of Appeal' },
  { name: 'supplierAgreement460', label: 'Supplier Agreement 460' },
  { name: 'certificateOfGoodStanding', label: 'Certificate of Good Standing' },
  { name: 'fictitousNamePermit', label: 'Fictitous Name Permit' },
  { name: 'bankLetter', label: 'Bank Letter' },
  { name: 'organizationChart', label: 'Organization Chart' },
];

export const entityTaxDocuments = [
  { name: 'irs575', label: 'IRS-575' },
  { name: 'w9', label: 'W-9' },
];

export const addressInitialValues = {
  addressLine1: '',
  zip: '',
  city: '',
  state: '',
  county: '',
  country: 'US',
};

export const defaultLocationValues: CreateEntityLocationPayload = {
  generalInfo: {
    locationType: '',
    locationName: '',
    suppressSiteFromDirectory: false,
    acceptsNewPatients: true,
    bedCounts: '',
    hasAdmittingPrivileges: false,
    department: '',
    isPrimaryLocation: true,
    tty: '',
  },
  contacts: [
    {
      type: '',
      name: '',
      mainPhoneNumber: '',
      afterHoursPhoneNumber: '',
      email: '',
      fax: '',
    },
  ],
  locationPrimarySpecialty: null,
  locationHsdSpecialty: null,
  serviceAddress: addressInitialValues,
  mailingAddress: { ...addressInitialValues, sameAsPrimary: true },
  billingAddress: { ...addressInitialValues, sameAsPrimary: true },
  officeHours: [
    {
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      hourFrom: '09:00',
      hourTo: '17:00',
    },
  ],
  hospitalAdmittingPrivileges: [],
  limitationsForMembers: [],
  adaCompliance: {},
};

export const DAYS_OF_WEEK_OPTIONS = [
  { label: 'Monday', value: 'Mon' },
  { label: 'Tuesday', value: 'Tue' },
  { label: 'Wednesday', value: 'Wed' },
  { label: 'Thursday', value: 'Thu' },
  { label: 'Friday', value: 'Fri' },
  { label: 'Saturday', value: 'Sat' },
  { label: 'Sunday', value: 'Sun' },
];

export const defaultProviderValues: CreateEntityProviderPayload = {
  firstName: '',
  lastName: '',
  providerType: '',
  states: [],
};
